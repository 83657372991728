import React from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IReversePsychube } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './rev-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { RevRarity } from '../modules/reverse/common/components/rev-rarity';
import { Reverse1999Psychube } from '../modules/reverse/common/components/rev-psychube';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IReverseCharacterNodes {
  nodes: IReversePsychube[];
}

interface IReverseCharacterEntry {
  currentUnit: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const ReversePsychubeDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  const fullImage = character.imageFull
    ? getImage(character.imageFull.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-reverse'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/psychubes">Psychubes</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        <Reverse1999Psychube mode="icon" slug={character.slug} />
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <RevRarity rarity={character.rarity} />
          </div>
        </div>
      </div>
      <div id="section-profile" className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Personal information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
          </Col>
          <Col>
            <h5>Combat information</h5>
            <div className="info-list-row">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}✦</div>
            </div>
          </Col>
          <Col>
            <h5>Tags</h5>
            <div className="specialities-list psychubes">
              {character.tags ? (
                character.tags.map((speciality, idx) => {
                  return <span key={idx}>{speciality}</span>;
                })
              ) : (
                <span className="no-spec">-</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </div>
      <div id="section-information" className="id-section ">
        <SectionHeader title="Information" />
        {character.stats ? (
          <>
            <Row xs={1} xxl={2} className="skills">
              <Col>
                <div className="skill-box-rev">
                  <div className={`skill-header Beast`}>Stats</div>
                  <div className="psychube-stats">
                    <Table striped bordered responsive className="cones-list">
                      <thead>
                        <tr>
                          <th>Stat</th>
                          <th>Lv. 1</th>
                          <th>Lv. 60</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="stat">ATK</td>
                          <td>{character.stats.atk.base}</td>
                          <td>{character.stats.atk.max}</td>
                        </tr>
                        <tr>
                          <td className="stat">HP</td>
                          <td>{character.stats.hp.base}</td>
                          <td>{character.stats.hp.max}</td>
                        </tr>
                        <tr>
                          <td className="stat">Reality DEF</td>
                          <td>{character.stats.reality_def.base}</td>
                          <td>{character.stats.reality_def.max}</td>
                        </tr>
                        <tr>
                          <td className="stat">Mental DEF</td>
                          <td>{character.stats.mental_def.base}</td>
                          <td>{character.stats.mental_def.max}</td>
                        </tr>
                        {character.stats.custom.name != '' ? (
                          <tr>
                            <td className="stat">
                              {character.stats.custom.name}
                            </td>
                            <td>{character.stats.custom.base}%</td>
                            <td>{character.stats.custom.max}%</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="skill-box-rev">
                  <div className={`skill-header Beast`}>Amplification</div>
                  <div className="box psychube-ampli">
                    {character.descriptionLevel1 ? (
                      <>
                        <p className="level">Lv. 1</p>
                        <div className="ampli-details first">
                          {renderRichText(character.descriptionLevel1, options)}
                        </div>
                        <p className="level">Lv. 5</p>
                        <div className="ampli-details">
                          {renderRichText(character.descriptionLevel5, options)}
                        </div>
                      </>
                    ) : (
                      <p className="no-effect">
                        This Psychube doesn't grant any special effects to the
                        wearer.
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>
                Information are not available for this psychube yet.
              </strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-impression" className="id-section">
        <SectionHeader title="Impression" />
        {character.impression ? (
          <div>{renderRichText(character.impression, options)}</div>
        ) : (
          '-'
        )}
      </div>
      <div id="section-gallery" className="id-section">
        <SectionHeader title="Gallery" />
        <h5>Full design</h5>
        {fullImage ? (
          <GatsbyImage image={fullImage} alt="" className="full-image" />
        ) : (
          <Alert variant="primary">
            <Alert.Heading>
              No full image is not available for this Psychube yet
            </Alert.Heading>
            <p>We will add it as soon as it is possible!</p>
          </Alert>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ReversePsychubeDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Reverse 1999 | Prydwen Institute'}
      description={
        'Discover the information and guides for ' +
        character.name +
        ' in Reverse 1999.'
      }
      image={character.imageFull}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulReversePsychube(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...ReversePsychubeFieldsFragment
      }
    }
  }
`;
